'use strict';

window.console || (window.console = {
	log: function log() {}
});
var FUNCTIONS = window.FUNCTIONS || null;
var cssua = window.cssua || null;

(function ($) {
	var siteName = '';
	var a = 'is-active';
	var v = 'is-visible';
	var slideSpeed = '400';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test.' + siteName + '.co.jp|copre.jp|localhost|192.168.0.|192.168.11.|192.168.1.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function () {
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on('click', function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					pos -= 90;
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		easeScrollHash: function easeScrollHash() {
			var hash = location.hash;
			if (hash) {
				console.log('hash', hash);
				$(window).on('load', function () {
					$('html, body').css('display', 'none');

					setTimeout(function () {
						$('html, body').css('display', 'block');

						var _hash = '#' + hash.split('#')[1];
						var pos = $(_hash).offset().top;
						console.log('_hash', _hash);
						console.log('pos', pos);

						pos -= 90;

						$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
					}, 500);
				});
			}
		},
		pageTop: function pageTop() {
			var $pagetop = $('.footer-pagetop');

			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				var $footer = $('.footer');
				// var footerPos = $footer.offset().top + $footer.height();
				var thisScroll = $this.scrollTop();
				// var pagetopPos = $this.height() + $footer.height() + thisScroll;

				if (thisScroll > '150') {
					$pagetop.addClass(v);
				} else {
					$pagetop.removeClass(v);
				}
			});
		},
		headerMenu: function headerMenu() {
			var $header = $('.header');
			var $menuBtn = $('.header-menuBtn');
			var $menu = $('.header-menu');
			var $headerIn = $('.header-inner');
			var s = 'is-small';

			setTimeout(function () {
				$header.addClass('is-fixed');
			}, 100);

			// メニュー開閉
			$menuBtn.on('click', function (e) {
				var $this = $(e.currentTarget);
				$this.toggleClass(a);
				$headerIn.toggleClass(a);
				$menu.toggleClass(a);
			});

			// スクロールしたらヘッダーを白くする（pc/tablet/sp）＆縮小(pc/tablet)
			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				var thisScroll = $this.scrollTop();
				if (thisScroll > 120) {
					$header.addClass(s);
				} else {
					$header.removeClass(s);
				}
			});
			if ($(window).scrollTop() > 120) {
				$header.addClass(s);
			} else {
				$header.removeClass(s);
			}
		},
		categoryMenu: function categoryMenu() {
			if ($('.mod-categorys').length) {
				$('.mod-categorys-item').find('a').each(function (idx, ele) {
					if ($(ele).attr('href') === location.pathname) {
						$(ele).addClass('is-current');
					}
				});
				if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
					var $switch = $('.js-categorys-sp-toggle');
					$switch.on('click', function () {
						$(this).toggleClass(a);
						$(this).next('.js-categorys-sp-toggleBlock').stop().slideToggle(slideSpeed);
					});
				}
			}
		},
		slider: function slider() {
			var arrowPrev = '<button type="button" class="slick-prev"><i class="icon icon-arrow_left"></button>';
			var arrowNext = '<button type="button" class="slick-next"><i class="icon icon-arrow_right"></button>';
			// PERSONリスト
			if ($('.mod-person-list').length) {
				$('.mod-person-list').slick({
					infinite: true,
					speed: 1500,
					autoplay: true,
					autoplaySpeed: 2500,
					slidesToShow: 4,
					arrows: true,
					prevArrow: arrowPrev,
					nextArrow: arrowNext,
					responsive: [{
						breakpoint: 1024,
						settings: {
							slidesToShow: 2
						}
					}]
				});
			}
		},
		socialBtn: function socialBtn() {
			var socialPouup = function socialPouup(_href) {
				var x = screen.width / 2 - 700 / 2;
				var y = screen.height / 2 - 450 / 2;
				window.open(_href, 'sharegplus', 'height=450,width=650,left=' + x + ',top=' + y);
			};
			var socialPouupSet = function socialPouupSet(_btn) {
				if (!(FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp)) {
					_btn.on('click', function () {
						socialPouup(_btn.attr('href'));
						return false;
					});
				}
			};
			if ($('.js-sosial-facebook').length) {
				var btn = $('.js-sosial-facebook');
				btn.attr('href', 'http://www.facebook.com/share.php?u=' + location.href);
				socialPouupSet(btn);
			}
			if ($('.js-sosial-twitter').length) {
				var _btn2 = $('.js-sosial-twitter');
				_btn2.attr('href', 'https://twitter.com/share?url=' + location.href + '&text=' + encodeURI(document.title));
				socialPouupSet(_btn2);
			}
		},
		recruitNav: function recruitNav() {
			if ($('.recruit-info-nav').length) {
				$('.recruit-info-nav-body').find('li').each(function (idx, ele) {
					var $map = location.pathname;
					if ($map === $(ele).find('a').attr('href')) {
						$(ele).find('a').addClass('color_red');
					}
				});
			}
		},
		aboutNav: function aboutNav() {
			if ($('.about-nav').length) {
				$('.about-nav-item').each(function (idx, ele) {
					if (location.pathname.indexOf($(ele).attr('href')) !== -1) {
						$(ele).addClass(a);
					}
				});
			}
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.easeScrollHash();
			_this.pageTop();
			_this.headerMenu();
			_this.categoryMenu();
			_this.slider();
			_this.socialBtn();
			_this.recruitNav();
			_this.aboutNav();
		}
	};

	$(function () {
		return FUNCTIONS.localDecision() ? FUNCTIONS.localLoading() : FUNCTIONS.loadDelayScript();
	});
})(window.jQuery);